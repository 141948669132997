<template>
  <div>
    <!-- <div class="tmHeader__header_small">{{ header }}</div> -->
    <show-case :sku="NBcornCards" />
  </div>
</template>

<script>
import ShowCase from "../components/ShowCase.vue";

import NBcornPeanat from "../assets/NUTBERRY/NBcorn/NB-corn-peanut.jpg";
import NBcornAlmond from "../assets/NUTBERRY/NBcorn/NB-corn-almond.jpg";
import NBcornCaramelSalt from "../assets/NUTBERRY/NBcorn/NB-corn-salt.jpg";
import NBcornCaramel from "../assets/NUTBERRY/NBcorn/NB-corn.jpg";

export default {
  name: "NutberryCorn",
  components: {
    ShowCase,
  },
  data() {
    return {
      header: "Попкорн высшего качества",
      NBcornCards: [
        {
          picture: NBcornPeanat,
          text: "NUTBERRY Попкорн карамель с арахисом 160&nbspг",
          id: 1,
          description:
            "Состав: Зерно кукурузы, сахар, глюкоза, арахис дробленый, масло растительное кокосовое, молоко сухое цельное, разрыхлитель - сода пищевая, эмульгатор -  лецитин соевый, соль поваренная пищевая, ароматизатор «Ванилин».<br>Продукт может содержать фрагменты арахиса.<br>Продукт сохраняет свои вкусовое качества в течение 24 часов после вскрытия упаковки.<br>Пищевая ценность в 100 г: Белки 4,5 г, Жиры 6,5 г, Углеводы 80 г.<br>Энергетическая ценность 390 Ккал.<br>Хранить при температуре от +5 °С до +25°С и относительной влажности воздуха не более 75%. Срок годности 9 месяцев.<br>ТУ 9164-002-60458573-15<br>Страна изготовитель: Россия",
        },
        {
          picture: NBcornAlmond,
          text: "NUTBERRY Попкорн карамель с миндалем 160&nbspг",
          id: 2,
          description:
            "Состав: Зерно кукурузы, сахар, глюкоза, ядро ореха миндаля дробленое, масло растительное кокосовое,  молоко сухое цельное, разрыхлитель - сода пищевая, эмульгатор- лецитин соевый, соль поваренная пищевая, ароматизатор «Горький миндаль», ароматизатор «Ванилин».<br>Продукт может содержать фрагменты арахиса.<br>Продукт сохраняет свои вкусовое качества в течение 24 часов после вскрытия упаковки.<br>Пищевая ценность в 100 г: Белки 4,5 г, Жиры 6,5 г, Углеводы 80 г.<br>Энергетическая ценность 390 Ккал.<br>Хранить при температуре не выше +20°С и относительной влажности воздуха не более 75%. Срок годности 9 месяцев.<br>ТУ 9164-002-60458573-15<br>Страна изготовитель: Россия",
        },
        {
          picture: NBcornCaramelSalt,
          text: "NUTBERRY Попкорн карамель сладко-соленая 160&nbspг",
          id: 3,
          description:
            "Состав: Зерно кукурузы, сахар, масло растительное пальмовое, глюкоза, соль поваренная пищевая, маргарин (рафинированные дезодорированные масла в натуральном и модифицированном виде (подсолнечное масло, пальмовое масло), вода, эмульгаторы: Е471, Е475, соль поваренная пищевая, ароматизатор, краситель (Е160b, Е100) , регулятор кислотности лимонная кислота), масло кокосовое,  эмульгатор -  лецитин соевый, ароматизатор «Ваниль», краситель Карамельный колер Е150d, ароматизатор «Сливки».<br>Продукт может содержать фрагменты арахиса.<br>Продукт сохраняет свои вкусовое качества в течение 24 часов после вскрытия упаковки.<br>Пищевая ценность в 100 г: Белки 3,7 г, Жиры 19,0 г, Углеводы 69 г.<br>Энергетическая ценность 462 Ккал.<br>Хранить при температуре от +5°С до +25°С и относительной влажности воздуха не более 75%. Срок годности 9 месяцев.<br>ТУ 9164-002-60458573-15<br>Страна изготовитель: Россия",
        },
        {
          picture: NBcornCaramel,
          text: "NUTBERRY Попкорн карамель 160&nbspг",
          id: 4,
          description:
            "Состав: Зерно кукурузы, сахар, глюкоза, масло растительное кокосовое, молоко сухое цельное, разрыхлитель - сода пищевая, эмульгатор -  лецитин соевый, соль поваренная пищевая, ароматизатор «Ванилин».<br>Продукт может содержать фрагменты арахиса.<br>Продукт сохраняет свои вкусовое качества в течение 24 часов после вскрытия упаковки.<br>Пищевая ценность в 100 г: Белки 4,5 г, Жиры 6,5 г, Углеводы 82 г.<br>Энергетическая ценность 398 Ккал.<br>Хранить в сухом затемненном месте при температуре до +25 °С и относительной влажности воздуха не более 75%. Срок годности 9 месяцев.<br>ТУ 10.61.33-017-59082654-2017<br>Страна изготовитель: Россия",
        },
      ],
    };
  },
};
</script>
